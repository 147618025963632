import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import LayoutGalerieImageInteractive from "../../../composants/layout/LayoutGalerieImageInteractive"
import Pannellum from "../../../composants/image/Panellum"

import { obtenirPage } from "../../../js/client-es/utils"
import fragmentsPages from "../../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    page: allPagesCompilationYaml(
      filter: { code: { eq: "desertDamaraland360" } }
    ) {
      ...FragmentPageYaml
    }
  }
`

export default function Damaraland360(props) {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "page")

  const composantImageInteractive = (
    <Pannellum urlImageSource="/ressources/360/damaraland.jpg" />
  )

  return (
    <LayoutGalerieImageInteractive
      page={page}
      propsPage={props}
      composantImageInteractive={composantImageInteractive}
      codePageRetour="galerieZoomsEt360Degres"
    >
      <section className="section-conteneur">
        <p>
          Une image à 360° prise en Namibie dans le Damaraland en 2019 : le
          décor était digne de la planète Mars. Promenez-vous dans l’image pour
          découvrir un ciel vierge de toute pollution lumineuse.
        </p>
        <p>
          Nous nous trouvions au milieu d’une petite plaine : la vue dégagée
          nous permettait de voir une immense portion de la voûte céleste : la
          grande Galaxie d’Andromède, les nuages de Magellan, les Pléiades, le
          bulbe Galactique, la nébuleuse North America, la lumière Zodiacale,
          Jupiter, Saturne, presque tout y est. Et cette Voie Lactée au
          couchant, si basse sur l’horizon Ouest et pourtant si visible grâce à
          la pureté du ciel.
        </p>
        <p>
          Hélas, de tels endroits sont en voie d’extinction, même le Chili est
          rongé par la pollution lumineuse.
        </p>
        <div id="galerie-image-interactive-texte-exifs">
          Panoramique de 28 images, rotule panoramique Nodal Ninja 3, Canon EOS
          6D refiltré Astrodon, Samyang 24 mm F1.4 @ F2.2, 3200 ISO. Poses
          unitaires de 20 secondes.
        </div>
      </section>
    </LayoutGalerieImageInteractive>
  )
}
